import React, { useEffect, useState } from "react";
import { Layout, Menu, theme } from "antd";
import "./CustomLayout.scss";
import Logo from "../../Assets/Images/mainLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../../Assets/Icons/DashboardIcon";
import AccountsIcon from "../../Assets/Icons/AccountsIcon";
import AnalyticsIcon from "../../Assets/Icons/AnalyticsIcon";
import ProfileIcon from "../../Assets/Icons/ProfileIcon";
import LogoutIcon from "../../Assets/Icons/LogoutIcon";
import User from "../../Assets/Images/CampionImg.png";
import { SearchIcon } from "../../Assets/Icons/icons";
import ThemeIcon from "../../Assets/Icons/ThemeIcon";
import TimeIcon from "../../Assets/Icons/TimeIcon";
import NotificationIcon from "../../Assets/Icons/NotificationIcon";
import UsersIcon from "../../Assets/Icons/UsersIcon";
import { useUserContext } from "../../Services/UserContext";

const { Header, Content, Sider } = Layout;

const CustomLayout = ({ children }) => {
  const { profileData } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const items = [
    {
      key: "1",
      icon: <DashboardIcon />,
      label: "Dash Board",
      path: "/dashboard",
    },
    {
      key: "2",
      icon: <AccountsIcon />,
      label: "Accounts",
      path: "/accounts",
      children: [
        {
          key: "2-1",
          label: "Website Traffic",
          path: "/accounts",
        },
        {
          key: "2-2",
          label: "Google Ads",
          path: "/google-ads",
        },
        {
          key: "2-3",
          label: "LinkedIn",
          path: "/linkedin-accs",
        },
        {
          key: "2-4",
          label: "G2",
          path: "/accounts",
        },
        {
          key: "2-5",
          label: "Email Traffic",
          path: "/email-accs",
        },
      ],
    },
    {
      key: "3",
      icon: <UsersIcon />,
      label: "People",
      path: "/people",
    },
    {
      key: "4",
      icon: <AnalyticsIcon />,
      label: "Analytics",
      path: "/analytics",
    },
    {
      key: "5",
      icon: <ProfileIcon />,
      label: "Profile",
      path: "/profile",
    },
    {
      key: "6",
      icon: <LogoutIcon />,
      label: "Logout",
      path: "/",
    },
  ];

  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleMenuClick = (path, key) => {
    setSelectedKeys([key]);
    navigate(path); 
  };

  useEffect(() => {
    const currentItem = items.find((item) => {
      if (item.path === location.pathname) return item;
      if (item.children) {
        return item.children.find((subItem) => subItem.path === location.pathname);
      }
      return null;
    });

    if (currentItem) {
      const keys = currentItem.children
        ? [
            currentItem.key,
            currentItem.children.find((subItem) => subItem.path === location.pathname).key,
          ]
        : [currentItem.key];
      setSelectedKeys(keys);
    }
  }, [location.pathname, items]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Header
        className="header"
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <div className="header_sec">
          <div className="header_sec_cont">
            <div
              className="header_sec_cont_left"
              onClick={() => handleMenuClick("/dashboard", "1")} 
            >
              <div className="header_sec_cont_left_logo">
                <img src={Logo} alt="logo" />
              </div>
            </div>
            <div className="header_sec_cont_right">
              <div className="header_sec_cont_right_search">
                <input type="text" placeholder="Search" />
                <SearchIcon className="searchicon" />
              </div>
              <div className="header_sec_cont_right_icons">
                <div className="theme">
                  <ThemeIcon />
                </div>
                <div className="time">
                  <TimeIcon />
                </div>
                <div className="notification">
                  <NotificationIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <Layout>
        <Sider
          className="sidebar"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {}}
          onCollapse={(collapsed, type) => {}}
        >
          <div
            className="sidebar_main"
            onClick={() => handleMenuClick("/profile", "5")} 
          >
            <img src={User} alt="User Image" />
            <h3>{profileData.name}</h3>
          </div>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={selectedKeys}
            items={items.map(({ key, icon, label, path, children }) => {
              if (children) {
                return {
                  key,
                  icon,
                  label,
                  children: children.map((subItem) => ({
                    key: subItem.key,
                    icon: subItem.icon,
                    label: subItem.label,
                    onClick: () => handleMenuClick(subItem.path, subItem.key), 
                  })),
                  className: "custom-menu-item",
                };
              }
              return {
                key,
                icon,
                label,
                onClick: () => handleMenuClick(path, key),
              };
            })}
          />
        </Sider>
        <Content className="content">
          <div className="content_center">{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
