import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Table, Empty, Select, Modal, Popover } from "antd";
import { useUserContext } from "../../Services/UserContext";
import { GET_BIRDVIEW_DATA } from "../../Utils/apiPath";
import { postApi } from "../../Utils/apiService";
import { Drawer } from "antd";
import "./GoogleAdsDetails.scss";
import Loader from "../../Components/Loader/Loader";
import WindowIcon from "../../Assets/Icons/WindowIcon";
import EmojiUserIcon from "../../Assets/Icons/EmojiIUserIcon";

const { TabPane } = Tabs;
const { Option } = Select;

const GoogleAdsDetails = () => {
  const navigate = useNavigate();
  const { profileData } = useUserContext();
  const location = useLocation();
  const rowData = location.state?.data;
  const [view, setView] = useState("Daily");
  const [birdViewData, setBirdViewData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const toggleExpandRow = (key) => {
    setExpandedRowKeys((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
    );
  };

  const fetchBirdViewData = useCallback(async () => {
    setIsLoading(true);
    const payload = {
      projectName: profileData.paidSearchProjectName,
      companyDomain: rowData.companyDomain,
    };
    const { statusCode, data } = await postApi(GET_BIRDVIEW_DATA, payload);
    if (statusCode === 200) {
      const formattedBirdViewData = data.flatMap((entry) =>
        entry.messageProperties.map((item, index) => {
          return {
            key: `${entry.id}-${index}`,
            date: item.firstSeenDate
              ? new Date(item.firstSeenDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })
              : "N/A",
            time: item.timestamp || "N/A",
            pageURL: item.pageURL,
            pageScrollPercent: item.pageScrollPercent || "-",
            pageActiveTime: item.pageActiveTime || "-",
            firstSeenDay: item.firstSeenDay || "-",
            pageReferrerUrl: item.pageReferrerUrl,
            userFirstPageRawUrl: item.userFirstPageRawUrl,
            userLatestPageRawUrl: item.userLatestPageRawUrl,
            userFirstPageUrl: item.userFirstPageUrl,
            userLatestPageURL: item.userLatestPageURL,
            pageRawUrl: item.pageRawUrl,
          };
        })
      );
      setIsLoading(false);
      setBirdViewData(formattedBirdViewData);

      const formattedActivityData = data.flatMap((entry) =>
        entry.messageProperties.map((item) => ({
          firstSeenDate: item.firstSeenDate
            ? new Date(item.firstSeenDate).toLocaleDateString()
            : "-",
          timestamp: item.timestamp
            ? new Date(item.timestamp * 1000).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
          pageURL: item.pageURL || "-",
          pageActiveTime: item.pageActiveTime || "-",
          userLatestPageURL: item.userLatestPageURL || "-",
          userID: item.userID || "-",
          userName: item.userName || "Anonymous User",
          userBrowser: item.userBrowser || "-",
          companyAnnualRevenueRange: item.companyAnnualRevenueRange || "-",
          companyEmployeeRange: item.companyEmployeeRange || "-",
          companyIndustry: item.companyIndustry || "-",
          userFirstChannel: item.userFirstChannel || "-",
          companyLinkedInUrl: item.companyLinkedInUrl || "-",
          userLatestPageURL: item.userLatestPageURL || "-",
          userFirstPageRawUrl: item.userFirstPageRawUrl || "-",
          userFirstPageActiveTime: item.userFirstPageActiveTime || "-",
          userTotalActiveTime: item.userTotalActiveTime || "-",
        }))
      );
      setIsLoading(false);
      setActivityData(formattedActivityData);
    } else {
      setIsLoading(false);
      console.error("Error: data is not in the expected format.");
    }
  }, [profileData, rowData]);

  useEffect(() => {
    fetchBirdViewData();
  }, [fetchBirdViewData]);

  const showDrawer = (record) => {
    setCurrentRecord(record);
    setIsDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
    setCurrentRecord(null);
  };

  const columns = [
    {
      title: "Date and Time",
      dataIndex: "date",
      key: "date",
      width: 150,
    },
    {
      title: "Anonymous Users",
      dataIndex: "pageURL",
      key: "pageURL",
      width: 150,
      render: (url, record) => {
        const allUrls = [
          record.pageURL,
          record.pageReferrerUrl,
          record.userFirstPageRawUrl,
          record.userLatestPageRawUrl,
          record.userFirstPageUrl,
          record.userLatestPageURL,
          record.pageRawUrl,
        ].filter(Boolean);

        const isExpanded = expandedRowKeys.includes(record.key);
        const displayedUrls = isExpanded ? allUrls : [allUrls[0]];
        const extraCount = allUrls.length - displayedUrls.length;

        return (
          <div>
            {displayedUrls.map((url, index) => (
              <Popover
                key={index}
                content={
                  <div className="modal-birdview">
                    <h2>Page View</h2>
                    <p>
                      <strong>Page URL:</strong> {record.pageURL || "-"}
                    </p>
                    <p>
                      <strong>Page Referrer URL:</strong>{" "}
                      {record.pageReferrerUrl || "-"}
                    </p>
                    <p>
                      <strong>Page Scroll Percent:</strong>{" "}
                      {record.pageScrollPercent || "-"}
                    </p>
                    <p>
                      <strong>Page Active Time:</strong>{" "}
                      {record.pageActiveTime || "-"}
                    </p>
                    <p>
                      <strong>First Seen Day:</strong>{" "}
                      {record.firstSeenDay || "-"}
                    </p>
                    <p>
                      <strong>User Latest Page URL:</strong>{" "}
                      {record.userLatestPageURL || "-"}
                    </p>
                  </div>
                }
                trigger="hover"
                placement="right"
              >
                <div style={{ marginBottom: "5px" }}>
                  <span style={{ color: url !== "N/A" ? "black" : "gray" }}>
                    {url !== "N/A" ? url : "-"}
                  </span>
                  <br />
                  {record.pageReferrerUrl !== "N/A" && (
                    <span style={{ fontSize: "0.9em", color: "gray" }}>
                      {record.pageReferrerUrl}
                    </span>
                  )}
                </div>
              </Popover>
            ))}

            {allUrls.length > 1 && !isExpanded && (
              <div>
                <a onClick={() => toggleExpandRow(record.key)}>+{extraCount}</a>
              </div>
            )}
            {isExpanded && allUrls.length > 1 && (
              <div>
                <a onClick={() => toggleExpandRow(record.key)}>Show Less</a>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Account Active",
      dataIndex: "pageActiveTime",
      key: "pageActiveTime",
      width: 150,
      render: (time) => {
        return time != null ? `${time} Sec` : "-";
      },
    }
  ];

  const fieldMappings = [
    { label: "Company Domain", key: "companyDomain" },
    { label: "Company Name", key: "companyName" },
    { label: "Company Employee Range", key: "companyEmployeeRange" },
    { label: "Company Industry", key: "companyIndustry" },
    { label: "Company Annual Revenue Range", key: "companyAnnualRevenueRange" },
    { label: "Account First Channel", key: "accountFirstChannel" },
    { label: "Account Latest Term", key: "accountLatetTerm" },
    {
      label: "Account Latest Referrer Domain",
      key: "accountLatestReferrerDomain",
    },
    { label: "Account Latest Campaign", key: "accountLatetCampaign" },
    { label: "Company Type", key: "companyType" },
    { label: "Company ID", key: "companyID" },
    { label: "Company Employee Count", key: "companyEmployeeCount" },
    { label: "Company State", key: "companyState" },
    { label: "Company Country", key: "companyCountryISOCode" },
    { label: "Company Legal Name", key: "companyLegalName" },
    { label: "Company Funding Raised", key: "companyFundingRaised" },
    { label: "Company Market Cap", key: "companyMarketCap" },
    { label: "Company SIC Code", key: "companySICCode" },
    { label: "Company Industry Group", key: "companyIndustryGroup" },
    { label: "Company Sub Industry", key: "companySubIndustry" },
    { label: "Company NAICS Code", key: "companyNAICSCode" },
    { label: "Company Sector", key: "companySector" },
    { label: "User Country", key: "userCountry" },
  ];

  const hanleAccountsPage = () => {
    navigate("/google-ads");
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="domain">
        <div className="domain_header">
          <h2>
            <span onClick={hanleAccountsPage}>Google Ads</span> /{" "}
            {rowData ? rowData.companyDomain : "-"}
          </h2>
        </div>
        <div className="domain_section">
          <div className="domain_section_left">
            <div className="domain_section_left_top">
              <img
                src={rowData.companyDomainUrl}
                onError={(e) =>
                  (e.target.src =
                    "https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png")
                }
                alt={"Logo"}
              />
              <a
                href={rowData ? `https://${rowData.companyDomain}` : "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {rowData ? rowData.companyDomain : "-"}
              </a>
            </div>
            <div className="domain_section_left_center">
              {fieldMappings.map(({ label, key }) => (
                <div className="domain_section_left_center_card" key={key}>
                  <p>{label}:</p>
                  <span>{rowData?.[key] || "-"}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="domain_section_right">
            <div className="content">
              <Tabs defaultActiveKey="1" style={{ marginTop: "20px" }}>
                <TabPane tab="Timeline" key="1">
                  <div className="timeline">
                    {activityData.map((entry, index) => (
                      <div key={index} className="timeline_header">
                        <h3>
                          {entry.firstSeenDay ? `${entry.firstSeenDay}, ` : ""}
                          {new Date(entry.firstSeenDate).toLocaleDateString(
                            "en-US",
                            {
                              weekday: "short",
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                            }
                          )}
                        </h3>
                        <div className="timeline_details">
                          <p style={{width:"10%"}}>
                            <strong>{entry.timestamp}</strong>
                          </p>
                          <div
                            className="timeline_details_view"
                            onClick={() => showDrawer(entry)}
                            style={{width:"15%"}}
                          >
                            <WindowIcon />
                            <p>Page View</p>
                          </div>
                          <span style={{width:"30%"}}>
                            Page URL:
                            {entry.pageURL !== "N/A" ? (
                              <p
                                href={entry.pageURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {entry.pageURL}
                              </p>
                            ) : (
                              "N/A"
                            )}
                          </span>
                          <div className="timeline_details_view">
                            <EmojiUserIcon />
                            <p>Anonymous User </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPane>
                <TabPane tab="Birdview" key="2">
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "10px",
                    }}
                  >
                    <Select value={view} onChange={setView}>
                      <Option value="Timestamp">Timestamp</Option>
                      <Option value="Hourly">Hourly</Option>
                      <Option value="Daily">Daily</Option>
                      <Option value="Weekly">Weekly</Option>
                      <Option value="Monthly">Monthly</Option>
                    </Select>
                  </div> */}
                  <Table
                    columns={columns}
                    dataSource={birdViewData}
                    pagination={false}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="No Data"
                        />
                      ),
                    }}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <Drawer
          title="More Details"
          placement="right"
          onClose={onCloseDrawer}
          open={isDrawerVisible}
          width={400}
        >
          <Tabs defaultActiveKey="1" style={{ marginTop: "20px" }}>
            <TabPane tab="User Properties" key="1">
              <div className="drawer">
                <div className="drawer_header">
                  <h2>{currentRecord?.userName}</h2>
                </div>
                {currentRecord && (
                  <div className="drawer_event">
                    <p>
                      <strong>Company Employee Range: </strong>
                      {currentRecord.companyEmployeeRange || "-"}
                    </p>
                    <p>
                      <strong>Company Industry: </strong>
                      {currentRecord.companyIndustry || "-"}
                    </p>
                    <p>
                      <strong>User Name: </strong>
                      {currentRecord.userName || "-"}
                    </p>
                    <p>
                      <strong>User Browser: </strong>
                      {currentRecord.userBrowser || "-"}
                    </p>
                    <p>
                      <strong>User ID: </strong>
                      {currentRecord.userID || "-"}
                    </p>
                    <p>
                      <strong>User Latest Page URL: </strong>
                      {currentRecord.userLatestPageURL || "-"}
                    </p>
                    <p>
                      <strong>User First Channel: </strong>
                      {currentRecord.userFirstChannel || "-"}
                    </p>
                    <p>
                      <strong>Company LinkedIn URL: </strong>
                      {currentRecord.companyLinkedInUrl || "-"}
                    </p>
                    <p>
                      <strong>User Latest Page URL: </strong>
                      {currentRecord.userLatestPageUrl || "-"}
                    </p>
                    <p>
                      <strong>User First Page Raw URL: </strong>
                      {currentRecord.userFirstPageRawUrl || "-"}
                    </p>
                    <p>
                      <strong>User First Page Active Time: </strong>
                      {currentRecord.userFirstPageActiveTime || "-"}
                    </p>
                    <p>
                      <strong>User Total Active Time: </strong>
                      {currentRecord.userTotalActiveTime || "-"}
                    </p>
                  </div>
                )}
              </div>
            </TabPane>
            <TabPane tab="Event Properties" key="2">
              <div className="drawer">
                <div className="drawer_header">
                  <h2>
                    <WindowIcon />
                    Page View
                  </h2>
                </div>
                {currentRecord && (
                  <div className="drawer_event">
                    <p>
                      <strong>Page URL: </strong>
                      {currentRecord.pageURL || "-"}
                    </p>
                    <p>
                      <strong>Page Referrer URL: </strong>
                      {currentRecord.pageReferrerURL || "-"}
                    </p>
                    <p>
                      <strong>Page Active Time: </strong>
                      {currentRecord.pageActiveTime || "-"}
                    </p>
                    <p>
                      <strong>Page Scroll Percent: </strong>
                      {currentRecord.pageScrollPercent || "-"}
                    </p>
                    <p>
                      <strong>Day Of Occurence: </strong>
                      {currentRecord.firstSeenDay || "-"}
                    </p>
                  </div>
                )}
              </div>
            </TabPane>
          </Tabs>
        </Drawer>
      </div>
    </>
  );
};

export default GoogleAdsDetails;
