import React from 'react'

const DashboardIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.81249 8.09609C6.90753 8.04123 6.98644 7.96231 7.04129 7.86727C7.09615 7.77223 7.12502 7.66442 7.12499 7.55469V1.92969C7.12443 1.83004 7.10006 1.73198 7.0539 1.64367C7.00774 1.55536 6.94113 1.47936 6.85964 1.42202C6.77814 1.36469 6.68412 1.32766 6.5854 1.31405C6.48669 1.30043 6.38616 1.31062 6.29218 1.34375C4.46794 1.98939 2.93383 3.26457 1.96563 4.94005C0.997427 6.61554 0.658632 8.58145 1.01015 10.4844C1.02837 10.5828 1.06995 10.6754 1.1314 10.7544C1.19285 10.8333 1.27237 10.8964 1.36327 10.9383C1.44519 10.9766 1.53456 10.9963 1.62499 10.9961C1.73469 10.9961 1.84247 10.9673 1.93749 10.9125L6.81249 8.09609ZM5.87499 2.87656V7.19375L2.13437 9.35234C2.12499 9.23438 2.12499 9.11563 2.12499 9C2.1261 7.73309 2.47678 6.49106 3.13843 5.41066C3.80007 4.33025 4.74701 3.45337 5.87499 2.87656ZM17.125 9C17.1256 10.7837 16.5393 12.518 15.4564 13.9354C14.3735 15.3528 12.8543 16.3745 11.1332 16.8428C9.41212 17.3111 7.58472 17.2 5.93298 16.5267C4.28125 15.8534 2.89698 14.6553 1.99374 13.1172C1.95164 13.0461 1.92403 12.9675 1.91251 12.8857C1.901 12.8039 1.9058 12.7207 1.92665 12.6407C1.9475 12.5608 1.98398 12.4859 2.03398 12.4201C2.08398 12.3544 2.14651 12.2992 2.21796 12.2578L8.37499 8.67422V1.5C8.37499 1.33424 8.44084 1.17527 8.55805 1.05806C8.67526 0.940848 8.83423 0.875 8.99999 0.875C10.4179 0.875723 11.8109 1.24729 13.0408 1.95282C14.2706 2.65834 15.2946 3.67328 16.0109 4.89688C16.0195 4.90938 16.0273 4.92188 16.0351 4.93516C16.043 4.94844 16.0508 4.96406 16.0578 4.97812C16.7588 6.20247 17.1268 7.58916 17.125 9Z" fill="#1C1C1C"/>
</svg>
  )
}

export default DashboardIcon;