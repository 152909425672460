import React, { useState, useEffect, useCallback } from "react";
import useTableHeight from "../../Services/TableHeight";
import { Table, Button, Popover, Checkbox } from "antd";
import { TableOutlined } from "@ant-design/icons";
import "./GoogleAds.scss";
import { postApi } from "../../Utils/apiService";
import { GET_ALL_WEBHOOKEVENTS } from "../../Utils/apiPath";
import { useUserContext } from "../../Services/UserContext";
import {
  formatTimeDifference,
  ResizableTitle,
} from "../../Services/HelperFunctions";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";

const GoogleAds = () => {
  const navigate = useNavigate();
  const { profileData } = useUserContext();
  const [tableData, setTableData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([
    "companyDomain",
    "companyEmployeeRange",
    "companyIndustry",
    "companyAnnualRevenueRange",
    "userFirstChannel",
    "userLatestTerm",
    "userLatestReferrerDomain",
    "userLatestCampaign",
    "userFirstTerm",
    "timestamp",
  ]);

  const [columns, setColumns] = useState([
    {
      title: "Company Domain",
      dataIndex: "companyDomain",
      width: 250,
      fixed: "left",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text && (
            <img
              src={record.companyDomainUrl}
              onError={(e) =>
                (e.target.src =
                  "https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png")
              }
              alt={`${text} logo`}
              style={{ width: "30px", height: "25px", marginRight: "8px" }}
            />
          )}
          <span
            style={{
              color: "black",
              cursor: "pointer",
            }}
            onClick={() => handleDomainClick(record)}
            onMouseEnter={(e) => {
              e.target.style.color = "blue";
              e.target.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "black";
              e.target.style.textDecoration = "none";
            }}
          >
            {text || "-"}
          </span>
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      width: 200,
      render: (companyName) => (companyName ? companyName : "-"),
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyName"),
      }),
    },
    {
      title: "Company Employee Range",
      dataIndex: "companyEmployeeRange",
      width: 150,
      render: (companyEmployeeRange) =>
        companyEmployeeRange ? companyEmployeeRange : "-",
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyEmployeeRange"),
      }),
    },
    {
      title: "Company Industry",
      dataIndex: "companyIndustry",
      width: 200,
      render: (companyIndustry) => (companyIndustry ? companyIndustry : "-"),
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyIndustry"),
      }),
    },
    {
      title: "Company Annual Revenue Range",
      dataIndex: "companyAnnualRevenueRange",
      width: 200,
      render: (companyAnnualRevenueRange) =>
        companyAnnualRevenueRange ? companyAnnualRevenueRange : "-",
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyAnnualRevenueRange"),
      }),
    },
    {
      title: "Company Annual Revenue",
      dataIndex: "companyAnnualRevenue",
      width: 200,
      render: (companyAnnualRevenue) =>
        companyAnnualRevenue ? companyAnnualRevenue : "-",
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyAnnualRevenue"),
      }),
    },
    {
      title: "Account First Channel",
      dataIndex: "userFirstChannel",
      width: 200,
      render: (userFirstChannel) =>
        userFirstChannel ? userFirstChannel : "-",
    },
    {
      title: "Account Latest Term",
      dataIndex: "userLatestTerm",
      width: 200,
      render: (userLatestTerm) =>
        userLatestTerm ? userLatestTerm : "-",
    },
    {
      title: "Account Latest Referrer Domain",
      dataIndex: "userLatestReferrerDomain",
      width: 200,
      render: (userLatestReferrerDomain) =>
        userLatestReferrerDomain ? userLatestReferrerDomain : "-",
    },
    {
      title: "Account Latest Campaign",
      dataIndex: "userLatestCampaign",
      width: 200,
      render: (userLatestCampaign) =>
        userLatestCampaign ? userLatestCampaign : "-",
    },
    {
      title: "Account First Term",
      dataIndex: "userFirstTerm",
      width: 200,
      render: (userFirstTerm) => (userFirstTerm ? userFirstTerm : "-"),
    },
    {
      title: "Company Type",
      dataIndex: "companyType",
      width: 150,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyType"),
      }),
    },
    {
      title: "Company ID",
      dataIndex: "companyID",
      width: 150,
    },
    {
      title: "Company Description",
      dataIndex: "companyDescription",
      width: 300,
    },
    {
      title: "Country ISO Code",
      dataIndex: "companyCountryISOCode",
      width: 150,
    },
    {
      title: "Traffic Rank",
      dataIndex: "companyTrafficRank",
      width: 150,
    },
    {
      title: "State",
      dataIndex: "companyState",
      width: 150,
    },
    {
      title: "Legal Name",
      dataIndex: "companyLegalName",
      width: 150,
    },
    {
      title: "Employee Count",
      dataIndex: "companyEmployeeCount",
      width: 150,
    },
    {
      title: "Funding Raised",
      dataIndex: "companyFundingRaised",
      width: 150,
    },
    {
      title: "Market Cap",
      dataIndex: "companyMarketCap",
      width: 150,
    },
    {
      title: "SIC Code",
      dataIndex: "companySICCode",
      width: 150,
    },
    {
      title: "Industry Group",
      dataIndex: "companyIndustryGroup",
      width: 150,
    },
    {
      title: "Sub Industry",
      dataIndex: "companySubIndustry",
      width: 150,
    },
    {
      title: "NAICS Code",
      dataIndex: "companyNAICSCode",
      width: 150,
    },
    {
      title: "Sector",
      dataIndex: "companySector",
      width: 150,
    },
    {
      title: "User Country",
      dataIndex: "userCountry",
      width: 150,
    },
    {
      title: "User Continent",
      dataIndex: "userContinent",
      width: 150,
    },
    {
      title: "User City",
      dataIndex: "userCity",
      width: 150,
    },
    {
      title: "User Postal Code",
      dataIndex: "userPostalCode",
      width: 150,
    },
    {
      title: "User State",
      dataIndex: "userState",
      width: 150,
    },
    {
      title: "User Email ID",
      dataIndex: "userEmailID",
      width: 150,
    },
    {
      title: "User ID",
      dataIndex: "userID",
      width: 150,
    },
    {
      title: "First Seen Date",
      dataIndex: "firstSeenDate",
      width: 150,
    },
    {
      title: "First Seen Hour",
      dataIndex: "firstSeenHour",
      width: 150,
    },
    {
      title: "First Seen Day",
      dataIndex: "firstSeenDay",
      width: 150,
    },
    {
      title: "Last Activity",
      dataIndex: "timestamp",
      width: 150,
      render: (timestamp) =>
        timestamp ? formatTimeDifference(timestamp) : "-",
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("timestamp"),
      }),
    },
  ]);

  const tableHeight = useTableHeight();

  const handleDomainClick = (record) => {
    navigate("/google-ads-details", { state: { data: record } });
  };

  const handleResize =
    (dataIndex) =>
    (e, { size }) => {
      setColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        const index = nextColumns.findIndex(
          (col) => col.dataIndex === dataIndex
        );
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const fetchWebHookEvents = useCallback(async () => {
    setIsLoading(true);
    const payload = {
      pageIndex: pageIndex - 1,
      pageSize,
      searchString,
      projectName: profileData.paidSearchProjectName,
    };

    const { statusCode, data, totalCount } = await postApi(
      GET_ALL_WEBHOOKEVENTS,
      payload
    );

    if (statusCode === 200) {
      setTableData(data);
      setTotalCount(totalCount);
      setIsLoading(false);
    } else {
      console.error("Error: data is not in the expected format.");
      setIsLoading(false);
    }
  }, [profileData, pageIndex, pageSize, searchString]);

  const debouncedFetch = useCallback(debounce(fetchWebHookEvents, 500), [
    fetchWebHookEvents,
  ]);

  useEffect(() => {
    if (profileData?.ProjectName) {
      debouncedFetch();
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [profileData, pageIndex, pageSize, searchString, debouncedFetch]);

  const handleColumnVisibilityChange = (columnKey) => {
    setVisibleColumns((prev) =>
      prev.includes(columnKey)
        ? prev.filter((key) => key !== columnKey)
        : [...prev, columnKey]
    );
  };

  const getPageSizeOptions = () => {
    if (totalCount <= 50) {
      return ["10", "20", "50"];
    } else {
      return ["10", "20", "50", "100"];
    }
  };

  const filteredColumns = columns.filter((col) =>
    visibleColumns.includes(col.dataIndex)
  );

  const popoverContent = (
    <div
      style={{
        width: "200px",
        maxHeight: "250px",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {columns.map((col) => (
        <Checkbox
          key={col.dataIndex}
          checked={visibleColumns.includes(col.dataIndex)}
          onChange={() => handleColumnVisibilityChange(col.dataIndex)}
        >
          {col.title}
        </Checkbox>
      ))}
    </div>
  );

  return (
    <>
      {isLoading && <Loader />}
      <div className="acc">
        <div className="acc_top">
          <div className="acc_top_left">
            <h2>Google Ads</h2>
          </div>
          <div className="acc_top_right">
            <input
              type="text"
              placeholder="Search..."
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <Popover
              content={popoverContent}
              title="Table Properties"
              trigger="click"
            >
              <Button icon={<TableOutlined />} />
            </Popover>
          </div>
        </div>
        <div className="acc_section">
          <div className="acc_section_table">
            {isLoading ? (
              <Loader />
            ) : (
              <Table
                bordered
                columns={filteredColumns}
                dataSource={tableData}
                components={{
                  header: {
                    cell: ResizableTitle,
                  },
                }}
                pagination={{
                  current: pageIndex,
                  pageSize,
                  total: totalCount,
                  showSizeChanger: true,
                  pageSizeOptions: getPageSizeOptions(),
                  onChange: (page, size) => {
                    setPageIndex(page);
                    setPageSize(size);
                  },
                }}
                scroll={{
                  x: 1500,
                  y: tableHeight,
                }}
                rowKey="companyID"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleAds;
