import React from 'react'

const AccountsIcon = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.875 0.125H2.125C1.79348 0.125 1.47554 0.256696 1.24112 0.491116C1.0067 0.725537 0.875 1.04348 0.875 1.375V12.625C0.875 12.9565 1.0067 13.2745 1.24112 13.5089C1.47554 13.7433 1.79348 13.875 2.125 13.875H15.875C16.2065 13.875 16.5245 13.7433 16.7589 13.5089C16.9933 13.2745 17.125 12.9565 17.125 12.625V1.375C17.125 1.04348 16.9933 0.725537 16.7589 0.491116C16.5245 0.256696 16.2065 0.125 15.875 0.125ZM15.875 1.375V2.625H2.125V1.375H15.875ZM15.875 12.625H2.125V3.875H15.875V12.625ZM12.75 5.75C12.75 6.74456 12.3549 7.69839 11.6517 8.40165C10.9484 9.10491 9.99456 9.5 9 9.5C8.00544 9.5 7.05161 9.10491 6.34835 8.40165C5.64509 7.69839 5.25 6.74456 5.25 5.75C5.25 5.58424 5.31585 5.42527 5.43306 5.30806C5.55027 5.19085 5.70924 5.125 5.875 5.125C6.04076 5.125 6.19973 5.19085 6.31694 5.30806C6.43415 5.42527 6.5 5.58424 6.5 5.75C6.5 6.41304 6.76339 7.04893 7.23223 7.51777C7.70107 7.98661 8.33696 8.25 9 8.25C9.66304 8.25 10.2989 7.98661 10.7678 7.51777C11.2366 7.04893 11.5 6.41304 11.5 5.75C11.5 5.58424 11.5658 5.42527 11.6831 5.30806C11.8003 5.19085 11.9592 5.125 12.125 5.125C12.2908 5.125 12.4497 5.19085 12.5669 5.30806C12.6842 5.42527 12.75 5.58424 12.75 5.75Z" fill="#1C1C1C"/>
</svg>

  )
}

export default AccountsIcon;