import { createContext, useContext, useEffect, useState } from "react";
import { GET_USER_PROFILE_DETAILS } from "../Utils/apiPath";
import { getApi } from "../Utils/apiService";

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getUserProfileData = async () => {
    setIsLoading(true);

    const { statusCode, data } = await getApi(GET_USER_PROFILE_DETAILS);
    if (statusCode === 200) {
      setIsLoading(false);
      setProfileData(data);
    } else {
      setIsLoading(false);
      setProfileData({});
    }
  };

  useEffect(() => {
    getUserProfileData();
  }, []);

  return (
    <UserContext.Provider value={{ isLoading, setIsLoading, profileData, setProfileData }}>
      {children}
    </UserContext.Provider>
  );
};
