import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Profile from "../Pages/Profile/Profile";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import AllAccounts from "../Pages/AllAccounts/AllAccounts";
import People from "../Pages/People/People";
import { UserProvider } from "../Services/UserContext";
import DomainDetails from "../Pages/DomainDetails/DomainDetails";
import LinkedIn from "../Pages/LinkedIn/LinkedIn";
import GoogleAds from "../Pages/GoogleAds/GoogleAds";
import CustomLayout from "../Components/CustomLayout/CustomLayout";
import EmailTraffic from "../Pages/EmailTraffic/EmailTraffic";
import GoogleAdsDetails from "../Pages/GoogleAdsDetails/GoogleAdsDetails";

// Wrapper for Protected Routes
const ProtectedRoutes = () => (
  <UserProvider>
    <CustomLayout>
    <Routes>

      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/accounts" element={<ProtectedRoute><AllAccounts /></ProtectedRoute>} />
      <Route path="/linkedin-accs" element={<ProtectedRoute><LinkedIn /></ProtectedRoute>} />
      <Route path="/google-ads" element={<ProtectedRoute><GoogleAds /></ProtectedRoute>} />
      <Route path="/google-ads-details" element={<ProtectedRoute><GoogleAdsDetails /></ProtectedRoute>} />
      <Route path="/email-accs" element={<ProtectedRoute><EmailTraffic /></ProtectedRoute>} />
      <Route path="/domain-details" element={<ProtectedRoute><DomainDetails /></ProtectedRoute>} />
      <Route path="/people" element={<ProtectedRoute><People /></ProtectedRoute>} />
    </Routes>
    </CustomLayout>
  </UserProvider>
);

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Unprotected Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Protected Routes wrapped with ContextProvider */}
        <Route path="/*" element={<ProtectedRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
