import React from 'react'

const AnalyticsIcon = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 0.75H11.5C11.0149 0.75 10.5364 0.862953 10.1025 1.07991C9.66853 1.29688 9.29108 1.61189 9 2C8.70892 1.61189 8.33147 1.29688 7.89754 1.07991C7.46362 0.862953 6.98514 0.75 6.5 0.75H1.5C1.16848 0.75 0.850537 0.881696 0.616116 1.11612C0.381696 1.35054 0.25 1.66848 0.25 2V12C0.25 12.3315 0.381696 12.6495 0.616116 12.8839C0.850537 13.1183 1.16848 13.25 1.5 13.25H6.5C6.99728 13.25 7.47419 13.4475 7.82583 13.7992C8.17746 14.1508 8.375 14.6277 8.375 15.125C8.375 15.2908 8.44085 15.4497 8.55806 15.5669C8.67527 15.6842 8.83424 15.75 9 15.75C9.16576 15.75 9.32473 15.6842 9.44194 15.5669C9.55915 15.4497 9.625 15.2908 9.625 15.125C9.625 14.6277 9.82254 14.1508 10.1742 13.7992C10.5258 13.4475 11.0027 13.25 11.5 13.25H16.5C16.8315 13.25 17.1495 13.1183 17.3839 12.8839C17.6183 12.6495 17.75 12.3315 17.75 12V2C17.75 1.66848 17.6183 1.35054 17.3839 1.11612C17.1495 0.881696 16.8315 0.75 16.5 0.75ZM6.5 12H1.5V2H6.5C6.99728 2 7.47419 2.19754 7.82583 2.54917C8.17746 2.90081 8.375 3.37772 8.375 3.875V12.625C7.83458 12.2183 7.17633 11.9989 6.5 12ZM16.5 12H11.5C10.8237 11.9989 10.1654 12.2183 9.625 12.625V3.875C9.625 3.37772 9.82254 2.90081 10.1742 2.54917C10.5258 2.19754 11.0027 2 11.5 2H16.5V12Z" fill="#1C1C1C"/>
</svg>
  )
}

export default AnalyticsIcon;